<template>
  <div>
    <b-row class="match-height">
      <b-col md="4" style="padding: -10px;;">
        <b-img :src="require('@/assets/images/logo/dasboard_admin.png')" class="congratulations-img-left" fluid />
        <info-validasi class="mt-1" :rekap = rekap></info-validasi>
      </b-col>
      <b-col md="8">
        <satistik :rekap=rekap></satistik>  
        <jenis-pembayaran-vue :items=items></jenis-pembayaran-vue>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import Base from '@/config/Mixins_base'
  import axios from '@/config/Axios'
  import {
    BRow,
    BCol,
    BImg
  } from 'bootstrap-vue'
  import satistik from './component/statistik.vue'
  import InfoValidasi from './component/InfoValidasi.vue'
  import jenisPembayaranVue from './component/jenisPembayaran.vue'
  export default {
    components: {
      satistik,
      BRow,
      BCol,
      InfoValidasi,
      InfoValidasi,
      BImg,
      jenisPembayaranVue
    },
    data() {
      return {
        rekap: {},
        items: []
      }
    },
    mixins: [Base],
    mounted() {
      this.rekap_jm_transaksi();
      this.load_histori();
    },
    methods: {
      async rekap_jm_transaksi() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/dashboard/admin/rekap_jm_transaksi',
            data: {

            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.rekap = response.data.result;

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async load_histori() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/dashboard/admin/load_histori',
            data: {

            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.items = response.data.result;
          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
    }
  }
</script>

<style>

</style>