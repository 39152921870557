<template>
    
    <b-card  text-variant="center" class="card card-congratulations bg-danger">  
        <b-avatar variant="primary" size="50" class="shadow">
            <feather-icon size="38" icon="UserIcon" class="text-white" />
        </b-avatar>
        <h3 class="mb-1 mt-1 text-warning">
            {{ data.mhs.nim }}
        </h3>
        <h6 class="mb-1 mt-1 text-warning" style="margin-top: -20px;;">
            {{ data.mhs.nm_lengkap }}
        </h6> 
        <b-card-text class="m-auto w-75">
           Kelas {{ data.mhs.kelas }} Tahun Angkatan {{  data.mhs.th_angkatan  }}
        </b-card-text>
        <b-card-text class="m-auto w-75">
             {{ data.mhs.no_hp }} 
        </b-card-text>
    </b-card>
</template>

<script>
    import {
        BCard,
        BImg,
        BAvatar,
        BCardText,
    } from 'bootstrap-vue'

    export default {
        components: {
            BCard,
            BAvatar,
            BImg,
            BCardText,
        },
        props: {
            data: {
                
            },
        },
    }
</script>