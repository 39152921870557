<template>
  <div>
    <b-row class="match-height"> 
      <b-col md="7">
        <b-row class="mb-1 mt-2">
          <b-col md="12" class="mt-5">
            <b-img :src="require('@/assets/images/logo/dasboard.png')" class="congratulations-img-left" fluid />
          </b-col>
        </b-row>
        <b-row class="match-height">
          <b-col md="6">
            <profil-mahasiswa :data= rs_mhs></profil-mahasiswa>
          </b-col>
          <b-col md="6">
            <histori-pembayaran :data = rs_mhs></histori-pembayaran>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="5">
        <b-card>
          <h5>
            <b-avatar rounded="" variant="light-warning" class="mr-1">
              <feather-icon icon="BellIcon" size="25" />
            </b-avatar> Alur Pembayaran
          </h5>
          <hr>
          <timeline :tahapan = tahapan></timeline>
        </b-card>

      </b-col>

    </b-row>
    <b-row>
      <b-col md="5">

      </b-col>
    </b-row>
  </div>
</template>

<script>
import Base from '@/config/Mixins_base';
import axios from '@/config/Axios';
  import {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BButton,
    BCard,
    BCardGroup,
    BCardText,
    BImg
  } from 'bootstrap-vue'
  import profilMahasiswa from './componet/profilMahasiswa.vue'
  import timeline from './componet/timeLinePembayaran.vue'
  import historiPembayaran from './componet/InfoPembayaran.vue'
  export default {
    components: {
      timeline,
      BTable,
      BAvatar,
      BBadge,
      BRow,
      BCol,
      BButton,
      BCard,
      profilMahasiswa,
      BCardGroup,
      BCardText,
      BImg,
      historiPembayaran
    },
    mixins:[Base],
    data(){
      return{
        rs_mhs : {},
        tahapan : {}
      }
    }, mounted(){
      this.dasboard_mhs();
      this.tahapan_pembayaran();
    },
    methods:{
      
      async dasboard_mhs() {
        const self = this;
        await axios({
            method: 'PUT',
            url: 'api/dashboard/dasboard_mhs',
            data: {

            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.rs_mhs = response.data.result;
          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async tahapan_pembayaran() {
          const self = this;
          await axios({
            method: 'PUT',
            url: '/api/dashboard/tahapan_pembayaran',
            data: {
              
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
            })
            .then(function (response) {
            self.tahapan = response.data.result;
            //self.rs_level = response.data.result;
      
            }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
            });
          },
    }
  }
</script>

<style>

</style>