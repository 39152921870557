<template>
    <div>
        <b-row> 
            <b-col md="12">
               <router-link to="/pembayaran">
                <b-card class="position-static card-congratulations bg-danger match-height">
                    <blockquote class="blockquote mb-0">
                        <h4 class="text-white">Pembayaran Aktif <strong class="text-white ml-1">({{ data.jm_pembayaran }})</strong></h4>
                        <footer class="blockquote-footer">
                            <small class="text-muted">
                                Klik disini untuk melaihat jenis pembayaran  yang sedang aktif
                            </small>
                        </footer>
                    </blockquote>
                </b-card>
               </router-link>

                <router-link to="/histori_pembayaran_mhs">
                    <b-card class="position-static bg-danger card-congratulations  match-height">
                    <blockquote class="blockquote mb-0">
                        <h4 class="text-white">Histori Pembayaran <strong class="text-white">({{ data.his_pembayaran }})</strong></h4>
                        <footer class="blockquote-footer">
                            <small class="text-muted">
                                Klik disini untuk melaihat histori transaksi pembayaran
                            </small>
                        </footer>
                    </blockquote>
                </b-card>
                </router-link>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BButton,
        BCard,
        BCardGroup,
        BCardText,
        BImg
    } from 'bootstrap-vue'
    export default {
        components: {
            BTable,
            BAvatar,
            BBadge,
            BRow,
            BCol,
            BButton,
            BCard,
            BCardGroup,
            BCardText,
            BImg
        },
        props:{
            data : {}
        }
    }
</script>

<style>

</style>