<template>
    <div> 
        <b-row class="match-height ">
            <b-col md="6">
                <b-card class="bg-gradient-warning" text-variant="center">
                    <!-- <feather-icon size="30" icon="BellIcon" class="text-white" /><br> -->
                    <!-- <feather-icon size="30" icon="AwardIcon" class="text-white" />  -->
                    <h4 style="font-size : 50px" class="text-white"><strong>{{ rekap.pengajuan }}</strong></h4>
                    <p class="card-text font-small-3"> Pengajuan Transaksi</p>
                    <!-- <b-button block variant="primary">
                        <feather-icon size="20" icon="BellIcon" class="text-white" /><br>
                        <small>Detail</small>
                    </b-button> -->
                </b-card>
            </b-col>
            <b-col md="6"> 
                <b-card class="bg-gradient-warning" text-variant="center">
                    <!-- <feather-icon size="30" icon="BellIcon" class="text-white" /><br> -->
                    <!-- <feather-icon size="30" icon="AwardIcon" class="text-white" />  -->
                    <h4 style="font-size : 50px" class="text-white"><strong>{{  rekap.konfirmasi}}</strong></h4>
                    <p class="card-text font-small-3"> Tagihan baru Belum Tervalidasi</p>
                    <!-- <b-button block variant="primary">
                        <feather-icon size="20" icon="BellIcon" class="text-white" /><br>
                        <small>Detail</small>
                    </b-button> -->
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {
        BRow,
        BCol,
        BCard,
        BAvatar,
        BButton
    } from 'bootstrap-vue'
    export default {
        components: {
            BRow,
            BCol,
            BCard,
            BAvatar,
            BButton
        },
        props:{
            rekap : {}
        }
    }
</script>

<style>

</style>