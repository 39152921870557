<template>
  <app-timeline>
    <app-timeline-item v-for="tahapan in tahapan" v-bind:key="tahapan" :title="tahapan.title"
      :subtitle="tahapan.keterangan" :icon="tahapan.icon" :variant="tahapan.varian" />
  </app-timeline>
</template>

<script>
  import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
  import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

  export default {
    components: {
      AppTimeline,
      AppTimelineItem,
    },
    props: {
      tahapan: {}
    }
  }
</script>