<template>
    <div>
        <b-card>
            <b-row> 
                <b-col md="4" sm="4">
                    <b-form-group class="mb-0">
                        <label class="d-inline-block text-sm-left mr-50"></label>
                        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions"
                            class="w-50" />
                    </b-form-group>
                </b-col>
                <b-col md="4">

                </b-col>
                <!-- <b-col md="3" sm="8" class="my-1">
                  
                </b-col> -->
                <b-col md="4">
                    <b-form-group label="" label-align-sm="left" label-size="sm" label-for="filterInput" class="mb-0">
                        <b-input-group size="sm">
                            <b-form-input id="filterInput" v-model="filter" type="search"
                                placeholder="Type to Search" />
                        </b-input-group>
                    </b-form-group>

                </b-col>

                <b-col cols="12" class="mt-1">
                    <b-table hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
                        :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                        :sort-direction="sortDirection" :filter="filter" :filter-included-fields="filterOn"
                        @filtered="onFiltered">

                        <template #cell(deskripsi)="data"> 
                            <b-row> 
                                <b-col md="5">
                                    <h5><small><strong>{{ data.item.nm_jenis_pembayaran }} (Kelas {{ data.item.kelas }})</strong></small> <br> Semester {{ data.item.nama }}
                                    </h5>
                                    <b-row>
                                        <b-col md="12">
                                            <small>Periode pembayaran  </small><br>
                                            <b-avatar variant="light-primary" rounded="" size="25" class="mr-1">
                                                <feather-icon icon="CalendarIcon" size="15" />
                                            </b-avatar>
                                            <small> {{ data.item.tgl_mulai }} - {{ data.item.tgl_selesai }} </small>
                                            <h6><small>Nominal Tagihan </small></h6>
                                            <h4 class="text-warning"><sup>Rp. </sup><strong>{{ Rp(data.item.nominal) }}</strong></h4> 
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col md="4" text-variant="center">
                                    <div variant="warning" show text-variant="center">
                                        <div class="alert-body">
                                            <small>Total pembayaran </small>
                                            <h4 class="text-primary"><sup>Rp. </sup><strong>{{ Rp(data.item.terbayar) }}</strong></h4> 
                                            <h6 class="text-warning"></h6>
                                            <small>No Rekening : </small><br>
                                            <small>  {{data.item.no_rekening}} /An {{ data.item.atas_nama }}</small>
                                        </div>
                                    </div>
                                </b-col>
                                <b-col md="3" text-variant="center">
                                    <b-button block variant="outline-warning">{{ data.item.tagihan }}  Pengajuan </b-button>
                                    <b-button block variant="outline-primary">{{ data.item.tervalidasi }}  Valid </b-button>
                                    <b-button block variant="outline-danger">{{ data.item.ditolak }}  Ditolak </b-button> 
                                </b-col>
                            </b-row>
                        </template>

                    </b-table>
                </b-col>
                <b-col cols="6">
                    
                </b-col>

                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right"
                        size="sm" class="my-0" />
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
    import {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BCard,
        BAlert,
        BLink
    } from 'bootstrap-vue'

    export default {
        components: {
            BTable,
            BAvatar,
            BBadge,
            BRow,
            BCol,
            BFormGroup,
            BFormSelect,
            BPagination,
            BInputGroup,
            BFormInput,
            BInputGroupAppend,
            BButton,
            BCard,
            BAlert,
            BLink
        },
        props: {
            items: {}
        },

        data() {
            return {
                perPage: 5,
                pageOptions: [3, 5, 10],
                totalRows: 1,
                currentPage: 1,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                infoModal: {
                    id: 'info-modal',
                    title: '',
                    content: '',
                },
                fields: [{
                    key: 'deskripsi',
                    label: '',
                }],
            }
        },
        computed: { 
            sortOptions() {
                // Create an options list from our fields
                return this.fields
                    .filter(f => f.sortable)
                    .map(f => ({
                        text: f.label,
                        value: f.key
                    }))
            },
        },
        mounted() {
            // Set the initial number of items
            this.totalRows = 0;
        },
        methods: {
            Rp(value) {
                let val = (value / 1).toFixed(0).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            },
            info(item, index, button) {
                this.infoModal.title = `Row index: ${index}`
                this.infoModal.content = JSON.stringify(item, null, 2)
                this.$root.$emit('bv::show::modal', this.infoModal.id, button)
            },
            resetInfoModal() {
                this.infoModal.title = ''
                this.infoModal.content = ''
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
        },
    }
</script>
<style>
    .bg-card {
        background: #dbdbdb;
    }
</style>